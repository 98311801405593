<template>
  <input
    class="survey-text-input-answer"
    placeholder="Значение"
    type="text"
    :value="answer"
    @input="(event) => $emit('setAnswer', event.target.value)"
  />
</template>

<script>
export default {
  name: 'NumericAnswer',
  props: [
    'answer',
    'disabled',
  ]
}
</script>
<style lang="scss">
.survey-text-input-answer{
  background: #FFFFFF;
  border: 1px solid #F9F9F9;
  width:100%;
  padding: 14px 16px;
  // border: 1px solid #DDE1E5;
  box-sizing: border-box;
  border-radius: 16px;
  &:focus-within{
    background: #FAFAFA;
    outline: none !important;
    border: 1.5px solid #EF7F1A;
  }
}
</style>