<template>
  <footer
    :style="{
      paddingTop: height+'px',
    }"
  >
    <div
      ref="content" 
      class="survey-bottom-nav-content"
    >
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click="handlePrevClick" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M28 16l-8 8 8 8" stroke="#151D24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><rect x=".5" y=".5" width="47" height="47" rx="23.5" stroke="#E8E6E5"/></svg>
      <template v-if="currentIndex < lastIndex">
        <span>
          {{ currentIndex + 1 }} из {{ lastIndex + 1 }}
        </span>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click="handleNextClick" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect width="48" height="48" rx="24" transform="matrix(-1 0 0 1 48 0)" fill="#101828"/><path d="M20 16l8 8-8 8" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </template>
        <template v-else>
          <div 
            class="survey-bottom-nav-content__submit-btn"
            @click="$emit('onSubmitClick')"
          >
            <span>Завершить</span>
          </div>
        </template>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SurveyBottomNav',
  props: ['currentIndex', 'lastIndex'],
  data(){
    return {
      height: 0,
    }
  },
  mounted(){
    this.$nextTick(() => {
      this.height = this.$refs.content?.offsetHeight || 0;
    })
  },
  methods: {
    handlePrevClick(){
      if (this.currentIndex > 0){
        this.$emit('setIndex', this.currentIndex-1);
      }
    },
    handleNextClick(){
      if (this.currentIndex < this.lastIndex){
        this.$emit('setIndex', this.currentIndex+1);
      }
    }
  }
}
</script>

<style lang="scss">
.survey-bottom-nav-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 8px 16px;
  background-color: #FFF;
  @supports(padding: max(0px)) {      // check if ios device 
    padding-bottom: calc(8px + env(safe-area-inset-bottom, 0px));
  }
  &__submit-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    margin-left: 16px;
    background: #EF7F1A;
    border-radius: 12px;
    flex: none;
    align-self: stretch;
    flex-grow: 1;
    span{
      font-family: 'TTCommons';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
      font-feature-settings: 'liga' off;
      color: #FFFFFF;
    }
  }
}
</style>