<template>
  <main 
    class="layout__body"
    :class="{
      'layout__body--centered': initError
    }"
  >
    <CenteredSpinner 
      :value="loading || submiting"
    />
    <template v-if="loading">
      <v-skeleton-loader
        class="survey-numbers-skeleton"
        type="avatar@5"
      ></v-skeleton-loader>
      <v-skeleton-loader
        type="list-item-three-line"
      ></v-skeleton-loader>
    </template>
    <template v-else-if="initError">
      <ErrorPlaceholder 
        :retry-loading="loading"
        :error="initError"
        :title="$translate('survey_error-title')"
        @retry="getSurveyQuestions"
      />
    </template>
    <template v-else>
      <NumbersList
        :activeIndex="activeIndex"
        :disabled="submiting"
        :length="questions.length"
        :answeredByIndex="isAnsweredList"
        @setActiveIndex="i => setIndex(i)"
      />
      <div class="question-content">
        <div class="question-content__title-container">
          <p 
            class="question-content__title"
            :class="{
              'question-content__title--mandatory': questions[activeIndex].is_mandatory
            }"
          >
            {{ questions[activeIndex].title }}
          </p>
          <div
            class="question-content__description"
            v-if="questions[activeIndex].description"
            v-html="questions[activeIndex].description"
          />
        </div>
        <template v-if="currentVariantIsConditional">
          <div class="question-content__conditinal-answer-title">
            <p 
              class="question-content__title"
              :class="{
                'question-content__title--mandatory': currentQuestion.is_mandatory
              }"
            >
              {{ currentQuestion.title }}
            </p>
            <div 
              class="question-content__conditinal-answer-title__x-btn"
              @click="
                $delete(answers, conditionalQuestionsByTriggerId[questions[activeIndex].id].id);
                $delete(answers, questions[activeIndex].id);
              "
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 21a9 9 0 100-18 9 9 0 000 18z" fill="#F6F6F6" stroke="#1C1D21" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/><path d="M15 9l-6 6M15 15L9 9" stroke="#1C1D21" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>
            </div>
          </div>
          <div
            class="question-content__description"
            v-if="currentQuestion.description"
            v-html="currentQuestion.description"
          />
        </template>
        <SimpleChoiceVariants 
          v-if="currentQuestion.question_type === 'simple_choice'"
          :answer="answers[currentId]"
          :disabled="submiting"
          :variants="currentQuestion.answers"
          @setAnswer="setAnswer"
        />
        <NumericAnswer 
          v-else-if="currentQuestion.question_type === 'numerical_box'"
          :answer="answers[currentId]"
          @setAnswer="setAnswer"
        />
        <MatrixAnswer 
          v-else-if="currentQuestion.question_type === 'matrix'"
          :answer="answers[currentId]"
          :disabled="submiting"
          :variants="currentQuestion.answers"
          :questions="currentQuestion.matrix_questions"
          :multiple="currentQuestion.matrix_subtype === 'multiple'"
          @setAnswer="setAnswer"
        />
        <TextAreaAnswer
          v-else-if="currentQuestion.question_type === 'char_box'"
          :answer="answers[currentId]"
          :disabled="submiting"
          @setAnswer="setAnswer"
        />
        <TextInputAnswer
          v-else-if="currentQuestion.question_type === 'text_box'"
          :answer="answers[currentId]"
          :disabled="submiting"
          @setAnswer="setAnswer"
        />
        <DateAnswer 
          v-else-if="currentQuestion.question_type === 'date' ||
            currentQuestion.question_type === 'datetime'
          "
          :key="currentId"
          :answer="answers[currentId]"
          :disabled="submiting"
          @setAnswer="setAnswer"
        />
        <MultipleChoiceVariants 
          v-else-if="currentQuestion.question_type === 'multiple_choice'"
          :answer="answers[currentId]"
          :disabled="submiting"
          :variants="currentQuestion.answers"
          @setAnswer="setAnswer"
        />
        <p v-else>Неизвестный тип вопроса</p>
      </div>
      <BottomNav
        :currentIndex="activeIndex"
        :disabled="submiting"
        :lastIndex="questions.length-1"
        @setIndex="i => setIndex(i)"
        @onSubmitClick="handleSubmit"
      />
    </template>
  </main>
</template>

<script>
import { backNavigateQueue } from "@/helpers/app.js";
import BottomNav from "@/components/Survey/BottomNav";
import CenteredSpinner from '@/components/reusable/CenteredSpinner';
import DateAnswer from "@/components/Survey/DateAnswer";
import ErrorPlaceholder from '@/components/reusable/ErrorPlaceholder.vue';
import MatrixAnswer from "@/components/Survey/MatrixAnswer";
import MultipleChoiceVariants from "@/components/Survey/MultipleChoiceVariants";
import baseApi from '@/helpers/baseApi.js';
import NumbersList from "@/components/Survey/NumbersList";
import NumericAnswer from "@/components/Survey/NumericAnswer";
import SimpleChoiceVariants from "@/components/Survey/SimpleChoiceVariants";
import TextAreaAnswer from "@/components/Survey/TextAreaAnswer";
import TextInputAnswer from "@/components/Survey/TextInputAnswer";

export default {
  name: 'SurveyByIdView',
  components: {
    BottomNav,
    CenteredSpinner,
    DateAnswer,
    ErrorPlaceholder,
    MatrixAnswer,
    MultipleChoiceVariants,
    NumbersList,
    NumericAnswer,
    SimpleChoiceVariants,
    TextAreaAnswer,
    TextInputAnswer,
  },
  props: {
    'id': {
      type: [String, Number],
      required: true,
    },
    'is_anonymous': {
      type: [String, Boolean],
      validator(value){
        return ['true', 'false', true, false].includes(value)
      }
    }
  },
  data(){
    return {
      activeIndex: 0,
      submiting: false,
      answers: {},
      conditionalQuestionsByTriggerId: {},
      loading: true,
      initError: undefined,
      questions: [],
    }
  },
  
  computed:{
    currentVariantIsConditional(){
      let questionId = this.questions[this.activeIndex]?.id;
      let triggeringAnswerId = this.conditionalQuestionsByTriggerId[questionId]?.triggering_answer_id
      return triggeringAnswerId && this.answers[questionId] === triggeringAnswerId
      // if (this.conditionalQuestionsByTriggerId[questionId]){
      // return this.questions[this.activeIndex].id
    },
    currentQuestion(){
      if (this.currentVariantIsConditional){
        return this.conditionalQuestionsByTriggerId[this.questions[this.activeIndex]?.id]
      } else {
        return this.questions[this.activeIndex]
      }
    },
    activeId(){
      return this.questions[this.activeIndex]?.id;
    },
    currentId(){
      return this.currentQuestion?.id
    },
    isAnsweredList(){
      return this.questions.map((question) => {
        if (question.question_type === 'matrix'){
          let answersLength;
          if (question.matrix_subtype === 'multiple'){
            answersLength = Object.keys(this.answers[question.id] || {})
              .filter(k => !!this.answers[question.id][k]?.length)
              .length;
          } else {
            answersLength = Object.keys(this.answers[question.id] || {}).length;
          }
          return question.matrix_questions.length === answersLength;
        }
        let hasUnansweredConditionalVariant = Object.keys(this.conditionalQuestionsByTriggerId).some((k) => (
          this.conditionalQuestionsByTriggerId[k].triggering_question_id === question.id 
          && this.conditionalQuestionsByTriggerId[k].triggering_answer_id === this.answers[question.id] 
          && !this.answers[this.conditionalQuestionsByTriggerId[k].id]
        ));
        return !!this.answers[question.id] && !hasUnansweredConditionalVariant;
      })
    },
    unansweredMandatoryIndexes(){
      return this.questions.reduce((acc, question, questionIndex) => {
        if (question.is_mandatory && !this.isAnsweredList[questionIndex]) {
          return [
            ...acc,
            questionIndex
          ]
        } else {
          return acc;
        }
      }, [])
    },
  },

  methods: {
    setIndex(i){
      this.activeIndex = i;
    },

    setAnswer(ans){
      this.$set(this.answers, this.currentQuestion.id, ans);
    },

    handleBackClick(){
      this.$router.replace({
        name: 'MainPage',
      })
    },

    handleSubmit(){
      // TODO validate here
      if (this.unansweredMandatoryIndexes.length){
        this.$store.dispatch('layout/alert', 'Нет ответов на вопросы: ' + this.unansweredMandatoryIndexes.map(i => i+1));
      } else {
        this.postSurveyAnswers();
      }
    },

    async getSurveyQuestions(){
      try {
        let res = await baseApi.get('/api/odoo_surveys/get_survey/'+this.id+'/'+(this.is_anonymous === 'true' || this.is_anonymous === true)+'/');
        if (res.data.result_form){
          this.$router.replace({
            name: 'FormPage',
            params: {
              form_data: res.data.result_form,
            },
          });
        } else {
          this.questions = res.data.filter(el => !el.is_conditional);
          this.conditionalQuestionsByTriggerId = res.data.reduce((acc, cur) => {
            if (cur.is_conditional){
              return {
                ...acc,
                [cur.triggering_question_id]: cur,
              }
            } else {
              return acc
            }
          }, {});
        }
      } catch (err) {
        if (err?.response?.data?.form){
          this.$router.replace({
            name: 'FormPage',
            params: {
              form_data: err?.response?.data?.form,
            },
          });
        } 
        this.initError = err;
      } finally {
        this.loading = false;
      }
    },

    async postSurveyAnswers(){
      try {
        if (this.submiting) return;
        this.submiting = true;
        let res = await baseApi.post('/api/odoo_surveys/send_survey/', {
          survey_id: this.id,
          is_anonymous: this.is_anonymous === 'true' || this.is_anonymous === true,
          answers: Object.keys(this.answers).map((question_id) => {
            let question = this.questions.find((q) => q.id === Number(question_id))
              || Object.values(this.conditionalQuestionsByTriggerId).find((q) => q.id === Number(question_id))
            return {
              answer_type: question.question_type,
              answer: this.answers[question_id],
              question_id,
            }
          }),
        });
        console.log(res);
        if (res?.data?.form){
          this.$router.replace({
            name: 'FormPage',
            params: {
              form_data: res?.data?.form,
            },
          });
        }
      } catch(err) {
        console.error(err);
        this.$store.dispatch('layout/alert', err)
      } finally {
        this.submiting = false;
      }
    }

  },
  mounted(){
    this.$store.dispatch('layout/setHeaderTitle', 'Опрос');
    backNavigateQueue.set(this.handleBackClick);
    this.getSurveyQuestions()
  }
}
</script>
<style lang="scss">
.survey-numbers-skeleton{
  display: flex;
  gap: 8px;
  padding: 16px;
}
.question-content{
  padding: 16px;
  &__title-container{
    padding-bottom: 24px;
  }
  &__title{
    font-family: 'TTCommons';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #1F1F1F;
    &--mandatory::after{
      content: "*";
      color: #F5463B;
    }
  }
  &__description{
    p{
      font-size: 16px;
      line-height: 20px;
      color: #5F5F5F;
    }
  }
  &__conditinal-answer-title{
    display: flex;
    justify-content: space-between;

    &__x-btn{

    }
  }
}
</style>