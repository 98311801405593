<template>
  <input
    class="survey-numeric-answer"
    placeholder="Значение"
    type="number"
    @input="(event) => $emit('setAnswer', event.target.value)"
  />
</template>

<script>
export default {
  name: 'NumericAnswer'
}
</script>
<style lang="scss">
.survey-numeric-answer{
  background: #FFFFFF;
  border: 1px solid #F9F9F9;
  width:100%;
  padding: 14px 16px;
  // border: 1px solid #DDE1E5;
  box-sizing: border-box;
  border-radius: 16px;
}
</style>