var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"survey-matrix"},[_vm._l((_vm.questions),function(question,qk){return [_c('p',{key:qk,staticClass:"survey-matrix__question"},[_vm._v(_vm._s(qk+1+'. '+question.title))]),_vm._l((_vm.variants),function(i,vk){return _c('div',{key:qk+'_'+vk+'_'+i.id,staticClass:"survey-matrix__variant",on:{"!click":function($event){return (function () { return _vm.handleAnswerClick(question.matrix_id, i.id); })($event)}}},[(_vm.multiple)?_c('v-simple-checkbox',{style:({
          alignSelf: 'flex-start',
          paddingTop: '3px'
        }),attrs:{"value":_vm.answer[question.matrix_id] &&
          _vm.answer[question.matrix_id].includes(i.id),"disabled":_vm.disabled,"color":"#EF7F1A","ripple":false}}):_c('div',{staticClass:"survey-matrix__variant__circle",class:{
          'survey-matrix__variant__circle--active': i.id === _vm.answer[question.matrix_id],
        }}),_c('span',{staticClass:"survey-matrix__variant__text",class:{
          'survey-matrix__variant__text--active': i.id === _vm.answer[question.matrix_id],
        }},[_vm._v(_vm._s(i.value))])],1)})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }