<template>
  <div class="simple-choice-variants">
    <div 
      class="simple-choice-variants__item"
      v-for="(i, k) in variants"
      :key="k+'_'+i.id"
      @click="$emit('setAnswer', i.id)"
    >
      <div 
        class="simple-choice-variants__item__circle"
        :class="{
          'simple-choice-variants__item__circle--active': i.id === answer,
        }"
      ></div>
      <span 
        class="simple-choice-variants__item__text"
        :class="{
          'simple-choice-variants__item__text--active': i.id === answer,
        }"
      >{{ i.value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimpleChoiceVariants',
  props: ['variants', 'answer']
}
</script>

<style lang="scss">
.simple-choice-variants{
  &__item{
    display: flex;
    align-items: center;
    padding: 8px 0;
    &__circle {
      flex: 0 0 auto;
      width: 20px;
      height: 20px;
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid #E8E8E8;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
      &--active {
        border: none;
        box-shadow:inset 0px 0px 0px 6px #EF7F1A;
      }
    }
    &__text {
      font-family: 'TTCommons';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #5F5F5F;
      margin-left: 12px;
      &--active {
        color: #EF7F1A;
      }
    }
  }
}
</style>