var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"layout__body",class:{
    'layout__body--centered': _vm.initError
  }},[_c('CenteredSpinner',{attrs:{"value":_vm.loading || _vm.submiting}}),(_vm.loading)?[_c('v-skeleton-loader',{staticClass:"survey-numbers-skeleton",attrs:{"type":"avatar@5"}}),_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}})]:(_vm.initError)?[_c('ErrorPlaceholder',{attrs:{"retry-loading":_vm.loading,"error":_vm.initError,"title":_vm.$translate('survey_error-title')},on:{"retry":_vm.getSurveyQuestions}})]:[_c('NumbersList',{attrs:{"activeIndex":_vm.activeIndex,"disabled":_vm.submiting,"length":_vm.questions.length,"answeredByIndex":_vm.isAnsweredList},on:{"setActiveIndex":function (i) { return _vm.setIndex(i); }}}),_c('div',{staticClass:"question-content"},[_c('div',{staticClass:"question-content__title-container"},[_c('p',{staticClass:"question-content__title",class:{
            'question-content__title--mandatory': _vm.questions[_vm.activeIndex].is_mandatory
          }},[_vm._v(" "+_vm._s(_vm.questions[_vm.activeIndex].title)+" ")]),(_vm.questions[_vm.activeIndex].description)?_c('div',{staticClass:"question-content__description",domProps:{"innerHTML":_vm._s(_vm.questions[_vm.activeIndex].description)}}):_vm._e()]),(_vm.currentVariantIsConditional)?[_c('div',{staticClass:"question-content__conditinal-answer-title"},[_c('p',{staticClass:"question-content__title",class:{
              'question-content__title--mandatory': _vm.currentQuestion.is_mandatory
            }},[_vm._v(" "+_vm._s(_vm.currentQuestion.title)+" ")]),_c('div',{staticClass:"question-content__conditinal-answer-title__x-btn",on:{"click":function($event){_vm.$delete(_vm.answers, _vm.conditionalQuestionsByTriggerId[_vm.questions[_vm.activeIndex].id].id);
              _vm.$delete(_vm.answers, _vm.questions[_vm.activeIndex].id);}}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M12 21a9 9 0 100-18 9 9 0 000 18z","fill":"#F6F6F6","stroke":"#1C1D21","stroke-width":"1.4","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M15 9l-6 6M15 15L9 9","stroke":"#1C1D21","stroke-width":"1.4","stroke-linecap":"round","stroke-linejoin":"round"}})])])]),(_vm.currentQuestion.description)?_c('div',{staticClass:"question-content__description",domProps:{"innerHTML":_vm._s(_vm.currentQuestion.description)}}):_vm._e()]:_vm._e(),(_vm.currentQuestion.question_type === 'simple_choice')?_c('SimpleChoiceVariants',{attrs:{"answer":_vm.answers[_vm.currentId],"disabled":_vm.submiting,"variants":_vm.currentQuestion.answers},on:{"setAnswer":_vm.setAnswer}}):(_vm.currentQuestion.question_type === 'numerical_box')?_c('NumericAnswer',{attrs:{"answer":_vm.answers[_vm.currentId]},on:{"setAnswer":_vm.setAnswer}}):(_vm.currentQuestion.question_type === 'matrix')?_c('MatrixAnswer',{attrs:{"answer":_vm.answers[_vm.currentId],"disabled":_vm.submiting,"variants":_vm.currentQuestion.answers,"questions":_vm.currentQuestion.matrix_questions,"multiple":_vm.currentQuestion.matrix_subtype === 'multiple'},on:{"setAnswer":_vm.setAnswer}}):(_vm.currentQuestion.question_type === 'char_box')?_c('TextAreaAnswer',{attrs:{"answer":_vm.answers[_vm.currentId],"disabled":_vm.submiting},on:{"setAnswer":_vm.setAnswer}}):(_vm.currentQuestion.question_type === 'text_box')?_c('TextInputAnswer',{attrs:{"answer":_vm.answers[_vm.currentId],"disabled":_vm.submiting},on:{"setAnswer":_vm.setAnswer}}):(_vm.currentQuestion.question_type === 'date' ||
          _vm.currentQuestion.question_type === 'datetime'
        )?_c('DateAnswer',{key:_vm.currentId,attrs:{"answer":_vm.answers[_vm.currentId],"disabled":_vm.submiting},on:{"setAnswer":_vm.setAnswer}}):(_vm.currentQuestion.question_type === 'multiple_choice')?_c('MultipleChoiceVariants',{attrs:{"answer":_vm.answers[_vm.currentId],"disabled":_vm.submiting,"variants":_vm.currentQuestion.answers},on:{"setAnswer":_vm.setAnswer}}):_c('p',[_vm._v("Неизвестный тип вопроса")])],2),_c('BottomNav',{attrs:{"currentIndex":_vm.activeIndex,"disabled":_vm.submiting,"lastIndex":_vm.questions.length-1},on:{"setIndex":function (i) { return _vm.setIndex(i); },"onSubmitClick":_vm.handleSubmit}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }