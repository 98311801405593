<template>
  <textarea 
    class="survey-text-answer"
    ref="textarea" 
    name="" 
    id="" 
    rows="1"
    v-model="computedValue"
    placeholder="Введите значение"
    @focus="$store.dispatch('layout/hideFooter')"
    @blur="$store.dispatch('layout/showFooter')"
  ></textarea>
</template>

<script>
export default {
  name: 'TextAnswer',
  props: ['answer'],
  data(){
    return {
      value: this.answer || '', 
    }
  },
  computed: {
    computedValue: {
      get(){
        return this.value;
      },
      set(newValue){
        this.value = newValue;
        this.$emit('setAnswer', newValue);
      },
    }
  },
  watch: {
    value(){
      this.calculateInputHeight();
    },
  },
  methods: {
    calculateInputHeight () {
      const input = this.$refs.textarea;
      input.style.height = '0';
      const height = input.scrollHeight;
      const minHeight = 20 * 3 + 28; //parseInt(1, 10) * parseFloat('24')
      input.style.height = Math.max(minHeight, height+2) + 'px';
    },
  },
  mounted () {
    setTimeout(() => {
      this.calculateInputHeight()
    }, 0)
  },
}
</script>
<style lang="scss">
.survey-text-answer{
  background: #FFFFFF;
  border: 1px solid #F9F9F9;
  resize: vertical;
  width:100%;
  padding: 14px 16px;
  // border: 1px solid #DDE1E5;
  box-sizing: border-box;
  border-radius: 12px;
  &:focus-within{
    background: #FAFAFA;
    outline: none !important;
    border: 1.5px solid #EF7F1A;
  }
  // &.textarea-form--disabled{
  //   color: #DDE1E5;
  // }
}
</style>