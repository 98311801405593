<template>
  <div class="survey_numbers-list">
    <div 
      v-for="(n, k) in arrFromLength"
      :key="n"
      ref="numItems"
      class="survey_numbers-list__number"
      :class="{
        'survey_numbers-list__number--answered': answeredByIndex[k] && k !== activeIndex,
        'survey_numbers-list__number--active': k === activeIndex
      }"
      @click="$emit('setActiveIndex', k)"
    >
      <span>{{ k+1 }}</span>
    </div>
  </div>
</template>

<script>
// let scrollTimeout;
export default {
  name: 'SurveyNumbersList',
  props: ['length', 'activeIndex', 'answeredByIndex', 'id'],
  computed: {
    arrFromLength(){
      return new Array(this.length)
    }
  },
  watch: {
    activeIndex(index){
      this.$refs.numItems[index].scrollIntoView({ 
        behavior: "smooth", 
        inline: "center",
      });
    }
  },
  // methods: {
  //   onScroll(){
  //     clearTimeout(scrollTimeout);
  //     scrollTimeout = setTimeout(function() {
  //       window.scrollTo({ top: 0, behavior: "smooth", })
  //     }, 30);
  //   }
  // },
  // mounted(){
  //   this.$el.addEventListener('scroll', this.onScroll);
  // },
  // beforeDestroy(){
  //   this.$el.removeEventListener('scroll', this.onScroll)
  // }
}
</script>

<style lang="scss">
.survey_numbers-list{
  display: block;
  overflow-x: scroll;
  padding: 0 0 4px 0;
  white-space: nowrap;
  background: #FFF;
  border-radius: 8px;
  &__number{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    background: #F9F9F9;
    border-radius: 50px;
    margin: 4px 8px 10px;
    width: 38px;
    height: 38px;
    &:first-child{
      margin-left: 16px;
    }
    &:last-child{
      margin-right: 16px;
    }
    .span{
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #101828;
    }
    &--answered{
      color: #FFF;
      background: #90BC34;
    }
    &--active{
      background: #F9F9F9;
      border: 2px solid #90BC34;
    }
  }
}
</style>