<template>
  <div
    class="datepicker-form"
  >
    <div class="datepicker-form__input-container">
      <input 
        class="datepicker-form__input"
        :class="{
          'datepicker-form__input--disabled': disabled,
        }"
        type="text" 
        readonly
        :title="title"
        :value="date"
        @click="(e) => {
          e.preventDefault();
          show_picker = !show_picker
        }"
      >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'datepicker-form__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.924 3a1 1 0 00-2 0v.5A4 4 0 002 7.5v10a4 4 0 004 4h12a4 4 0 004-4v-10a4 4 0 00-4-4h-.23V3a1 1 0 10-2 0v.5H7.924V3zm7.846 3v-.5H7.924V6a1 1 0 01-2 0v-.499A2 2 0 004 7.5v10a2 2 0 002 2h12a2 2 0 002-2v-10a2 2 0 00-2-2h-.23V6a1 1 0 11-2 0zM7 14a1 1 0 100 2h10a1 1 0 100-2H7z" fill="#0D1616"/></svg>
    </div>
    <p 
      v-if="error_msg"
      class="FORMS__error-msg"
    >{{ error_msg }}</p>
    <div class="datepicker-form__picker">
      <v-date-picker
        v-if="show_picker && !disabled"
        v-model="date"
        active-picker="DATE"
        color="#EF7F1A"
        :first-day-of-week="1"
        full-width
        locale="ru-RU"
        :max="maxDate"
        :min="minDate"
        show-adjacent-months
        style="border-radius: 12px;"
        type="date"
        :weekday-format="weekdayFormat"
        @change="show_picker = false"
        @click.stop.prevent
      ></v-date-picker>
      <!-- :header-date-format="headerDateFormat" -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'DatePickerForm',
  props: ["answer", "error_msg", "disabled"],
  data(){
    return {
      show_picker: false,
      title: this.title || 'Выберите дату',
      // new Date().toISOString().substr(0, 10),
      // TODO: FIX Dates when backend sends in ISO format  
      date: ( this.answer 
        ? new Date(this.answer) 
        : new Date() ).toISOString().substr(0, 10),
      minDate: ( this?.min_date
        ? new Date(this?.min_date).toISOString().substr(0, 10) // max_date
        : undefined),
      maxDate: ( this?.max_date
        ? new Date(this?.max_date).toISOString().substr(0, 10)
        : undefined),
        
    }
  },

  watch: {
    date: {
      handler(newValue){
        this.$emit('setAnswer', newValue)
      },
      immediate: true,
    }
  },

  methods: {

    // headerDateFormat(d){
    //   console.log(d);
    //   let [year, month] = d.split('-');
    //   let date = new Date(Date.UTC(2012, Number(month)-1, 20, 3, 0, 0, 200));
    //   let monthName = new Intl.DateTimeFormat('ru-RU', { month: 'long' }).format(date);
    //   monthName = monthName[0].toUpperCase() + monthName.slice(1);
    //   return `${monthName}, ${year}`;
    // },

    weekdayFormat(d){
      let date = new Date(d);
      let weekName = new Intl.DateTimeFormat('ru-RU', { weekday: 'short' }).format(date);
      return weekName;
    }
  }
}
</script>
<style lang="scss">
.datepicker-form{
  position: relative;
  margin: 0.375rem 16px;
  .datepicker-form__input-container{
    position: relative;
    .datepicker-form__input{
      background: #FFFFFF;
      border: 1px solid #F9F9F9;
      width:100%;
      padding: 14px 16px;
      // border: 0.5px solid #DDE1E5;
      box-sizing: border-box;
      border-radius: 16px;
      &:focus{
        outline: none;
      }
      &.datepicker-form__input--disabled{
        color: #DDE1E5;
      }
    }
    .datepicker-form__icon{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      margin: auto;
      margin-right: 1.5rem;
      pointer-events: none; 
    }
  }
  .datepicker-form__picker{
    position: absolute;
    // top: 0;
    margin-top: 8px;
    z-index: 2;
    width: 100%;
    .v-date-picker-header{
      margin: 4px 16px 8px;
      padding: 0 0 8px; 
      border-bottom: 2px solid rgba(39, 41, 55, 0.12);
      // border-radius: 2px;
    }
    th{
      text-transform: capitalize;
    }
  }
}
</style>