<template>
  <div class="survey-matrix">
    <template v-for="(question, qk) in questions">
      <p 
        :key="qk"
        class="survey-matrix__question"
      >{{ qk+1+'. '+question.title }}</p>
      <div 
        class="survey-matrix__variant"
        v-for="(i, vk) in variants"
        :key="qk+'_'+vk+'_'+i.id"
        @click.capture="() => handleAnswerClick(question.matrix_id, i.id)"
      >
        <v-simple-checkbox
          v-if="multiple"
          :value="
            answer[question.matrix_id] &&
            answer[question.matrix_id].includes(i.id)
          "
          :disabled="disabled"
          color="#EF7F1A"
          :ripple="false"
          :style="{
            alignSelf: 'flex-start',
            paddingTop: '3px'
          }"
        ></v-simple-checkbox>
        <div 
          v-else
          class="survey-matrix__variant__circle"
          :class="{
            'survey-matrix__variant__circle--active': i.id === answer[question.matrix_id],
          }"
        ></div>
        <span 
          class="survey-matrix__variant__text"
          :class="{
            'survey-matrix__variant__text--active': i.id === answer[question.matrix_id],
          }"
        >{{ i.value }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'MatrixAnswer',
  props: {
    answer: {
      type: Object,
      default(){
        return {};
      }
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    variants: {
      type: Array,
    }, 
    questions: {
      type: Array,
    }, 
  },
  methods: {
    handleAnswerClick(questionId, answerId){
      if (this.multiple){
        if (this.answer?.[questionId]?.includes(answerId)){
          const index = this.answer[questionId].indexOf(answerId);
          this.$emit('setAnswer', {
            ...this.answer,
            [questionId]: this.answer[questionId].filter((el, i) => i !== index),
          })
        } else {
          this.$emit('setAnswer', {
            ...this.answer,
            [questionId]: [
              ...(this.answer[questionId] ?? []),
              answerId,
            ]
          })
        }
      } else {
        this.$emit('setAnswer', {
          ...this.answer,
          [questionId]: answerId
        })
      }
    }
  },
}
</script>

<style lang="scss">
.survey-matrix{
  &__variant{
    display: flex;
    align-items: center;
    padding: 8px 0;
    &__circle {
      flex: 0 0 auto;
      width: 20px;
      height: 20px;
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid #E8E8E8;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
      &--active {
        border: none;
        box-shadow:inset 0px 0px 0px 6px #EF7F1A;
      }
    }
    &__text {
      font-family: 'TTCommons';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #5F5F5F;
      margin-left: 12px;
      &--active {
        color: #EF7F1A;
      }
    }
  }
}
</style>