<template>
  <div class="multiple-choice">
    <div 
      class="multiple-choice__item"
      v-for="(i, k) in variants"
      :key="k+'_'+i.id"
      @click.capture="handleVariantClick(i.id)"
    >
      <v-simple-checkbox
        :value="answer.includes(i.id)"
        :disabled="disabled"
        color="#EF7F1A"
        :ripple="false"
        :style="{
          alignSelf: 'flex-start',
          paddingTop: '3px'
        }"
      ></v-simple-checkbox>
      <span 
        class="multiple-choice__item__text"
        :class="{
          'multiple-choice__item__text--active': answer.includes(i.id),
        }"
      >{{ i.value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimpleChoiceVariants',
  props: {
    'variants': {
      type: Array,
    }, 
    'answer': {
      type: Array,
      default(){
        return []
      }
    }, 
    'disabled': {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleVariantClick(id){
      console.log(id);
      if (this.answer.includes(id)){
        const index = this.answer.indexOf(id);
        this.$emit('setAnswer', this.answer.filter((el, i) => i !== index),);
      } else {
        this.$emit('setAnswer', [
          ...this.answer,
          id
        ])
      }
    }
  },
}
</script>

<style lang="scss">
.multiple-choice{
  &__item{
    display: flex;
    // align-items: center;
    margin: 0 0 8px;
    .mdi-checkbox-blank-outline{
      &.v-icon{
        color: #DDE1E5;
      }
    }
    &__text{
      font-family: 'TTCommons';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #5F5F5F;
      margin-left: 12px;
      user-select: none;
      &--active {
        color: #EF7F1A;
      }
    }
  }
}
</style>